import {
  clearFeatureFlags,
  clearSaveFeatureFlagSuccess,
  // fetchFeatureFlagByTermSuccess,
  fetchFeatureFlagsSuccess,
  setFeatureFlagsLoadingFlag,
  updateFeatureFlagsSuccess,
} from "../actions/featureFlagActions"

// import { FEATURE_FLAGS } from "../../constants"
import { FeatureFlag } from "../types/featureFlagTypes"
import { createReducer } from "@reduxjs/toolkit"
// import { findAndReturnValueOfFeatureFlag } from "../../helpers/utilityHelpers"

interface InitialFeatureFlagState {
  featureFlags: FeatureFlag[]
  isLoading: boolean
  isSubmitting: boolean
  updateFeatureFlagsSuccessMessage: string

  // Individual feature flag state (to be removed later)
  // isDevCycleFFEnabled: boolean
}

export const initialState: InitialFeatureFlagState = {
  featureFlags: [],
  isLoading: true,
  isSubmitting: false,
  updateFeatureFlagsSuccessMessage: "",

  // Individual feature flag state (to be removed later)
  // isDevCycleFFEnabled: false
}

export default createReducer(initialState, (builder) =>
  builder
    /*================================ FeatureFlag List action handlers ================================*/
    .addCase(setFeatureFlagsLoadingFlag, (state, action) => {
      state.isLoading = action.payload
    })
    .addCase(fetchFeatureFlagsSuccess, (state, action) => {
      state.featureFlags = action.payload.featureFlags
    })
    // .addCase(fetchFeatureFlagByTermSuccess, (state, action) => {
    // const featureFlags: FeatureFlag[] = action.payload.featureFlags
    // state.isDevCycleFFEnabled = findAndReturnValueOfFeatureFlag(
    //   FEATURE_FLAGS.FF_USE_DEVCYCLE,
    //   featureFlags
    // )
    // })
    .addCase(updateFeatureFlagsSuccess, (state, action) => {
      state.updateFeatureFlagsSuccessMessage = action.payload
    })
    .addCase(clearSaveFeatureFlagSuccess, (state) => {
      state.updateFeatureFlagsSuccessMessage = initialState.updateFeatureFlagsSuccessMessage
    })
    .addCase(clearFeatureFlags, () => initialState)
)
